<template>
	<div class="store-plan">
		<div v-for="(planItem, planIndex) in visitPlanList" :key="planIndex" class="plan-item">
			<div class="plan-info">
				<div class="plan-name">{{ planItem.planName }}</div>
				<div class="loc-num">{{ planItem.detailCount }} locations</div>
			</div>
			<van-icon size="26" color="#007bff" name="guide-o" @click="toVisitStore(planItem)" />
		</div>
	</div>
</template>

<script>
import { arsVisitPlanListApi } from '@/api/plan'
export default {
	data() {
		return {
			visitPlanList: []
		}
	},
	created () {
    this.getVisitPlanList()
	},
	activated() {
		this.getVisitPlanList()
	},
  methods: {
		getVisitPlanList() {
			arsVisitPlanListApi().then(res => {
				console.log('arsVisitPlanListApi', res)
				this.visitPlanList = res.data
			})
		},
		toVisitStore(params) {
      this.$router.push(`/plan/planLoc?planId=${params.planId}`)
		}
	}
}
</script>

<style lang="less" scoped>
.store-plan {
	font-size: 12px;
	.plan-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px;
		border-bottom: 1px solid #eeeeee;
		.plan-name {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.loc-num {
			color: #007bff;
		}
	}
}
</style>
